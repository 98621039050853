// Convert bytes to their nearest unit.
function formatByteSize(fileSizeInBytes) {
	let i = -1;
	const byteUnits = [' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
	do {
		fileSizeInBytes = fileSizeInBytes / 1000;
		i++;
	} while (fileSizeInBytes > 1000);

	return fileSizeInBytes.toFixed(2) + byteUnits[i];
}

// Format number (with commas).
function formatNumber(num) {
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

async function fetchData(url, type, dataObject = null, callback = null) {
	if (!url) {
		const errorMessage = "Request URL not provided!";
		console.error(errorMessage);
		if (typeof Sentry != "undefined") {
			Sentry.captureMessage(errorMessage);
		}

		return {
			success: false,
			data: false,
			error: errorMessage,
			statusCode: 0
		};
	}

	if (!type) {
		const errorMessage = "Request type not provided!";
		console.error(errorMessage);
		if (typeof Sentry != "undefined") {
			Sentry.captureMessage(errorMessage);
		}

		return {
			success: false,
			data: false,
			error: errorMessage,
			statusCode: 0
		};
	}

	// If a request type is not valid.
	if (!["GET", "PUT", "POST", "DELETE"].includes(type)) {
		const errorMessage = "Request type (" + type + ") not supported.";
		console.error(errorMessage);

		return {
			success: false,
			data: false,
			error: errorMessage,
			statusCode: 0
		};
	}

	// Set request options.
	let opts = {
		cache: "no-cache",
		redirect: "follow",
		method: type
	}

	// Preform the request.
	try {
		// If we are sending JSON object to the server, attach it to the body.
		if (dataObject) {
			let dataObjectJSONString = JSON.stringify(dataObject);
			if (dataObjectJSONString) {
				if (dataObjectJSONString !== "{}") {
					opts.headers = {
						"Content-Type": "application/json"
					};
					opts.body = dataObjectJSONString;
				} else {
					const errorMessage = "Outgoing JSON object is empty ({}), not sending!";
					console.error(errorMessage);
					if (typeof Sentry != "undefined") {
						Sentry.captureMessage(errorMessage);
					}

					return {
						success: false,
						data: false,
						error: errorMessage,
						statusCode: 0
					}
				}
			} else {
				const errorMessage = "Failed to convert outgoing data to JSON string.";
				console.error(errorMessage);
				if (typeof Sentry != "undefined") {
					Sentry.captureMessage(errorMessage);
				}

				return {
					success: false,
					data: false,
					error: errorMessage,
					statusCode: 0
				}
			}
		}

		const response = await fetch(url, opts);
		if (response) {
			const responseJSON = await response.json();
			if (JSON.stringify(responseJSON) !== "{}") {
				if (callback) {
					callback(responseJSON);
				}

				return {
					success: true,
					data: responseJSON,
					error: false,
					statusCode: response.status
				}
			} else {
				return {
					success: true,
					data: false,
					error: false,
					statusCode: response.status
				}
			}
		} else {
			// Response did not include any JSON data, there was a problem.
			if (response.body) {
				return {
					success: false,
					data: false,
					error: response.body,
					statusCode: response.status
				}
			} else {
				return {
					success: false,
					data: false,
					error: false,
					statusCode: response.status
				}
			}
		}
	} catch (err) {
		if (typeof Sentry != "undefined") {
			Sentry.captureMessage(err);
		}
		console.error(err);

		return {
			success: false,
			data: false,
			error: "The server encountered an unhandled error.",
			statusCode: 0
		}
	}
}

// Only update attribute if it doesn't exist or is different.
function updateIfDifferentAttribute(target_object, attribute_key, attribute_value) {
	if (target_object !== null && (!target_object.hasAttribute(attribute_key) || target_object.getAttribute(attribute_key) !== attribute_value)) {
		target_object.setAttribute(attribute_key, attribute_value);
		return true;
	} else {
		return false;
	}
}

// Only update innerHTML if they are different then what's current.
function updateIfDifferentHTMLContents(target_object, source_string) {
	if (target_object !== null && target_object !== undefined) {
		if (target_object.innerHTML !== null && target_object.innerHTML !== source_string) {
			target_object.innerHTML = source_string;
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
}

// Only update HTML options if they are actually different then what's current.
function updateIfDifferentOptions(target_object, selected_option, options, isList) {
	let options_string = "";
	Object.keys(options).forEach(function(key) {
		if (Array.isArray(options[key])) {
			if (isList === true) {
				if (options[key][1] >= 0) {
					options[key][1] = "+" + options[key][1];
				}
				if (options[key][0] === selected_option) {
					options_string += '<option value="' + options[key][0] + '" selected>' + options[key][0] + ' (UTC ' + options[key][1] + ')</option>';
				} else {
					options_string += '<option value="' + options[key][0] + '">' + options[key][0] + ' (UTC ' + options[key][1] + ')</option>';
				}
			} else {
				if (key === selected_option) {
					options_string += '<option value="' + key + '" selected>' + key + ' (' + options[key][0] + ')</option>';
				} else {
					options_string += '<option value="' + key + '">' + key + ' (' + options[key][0] + ')</option>';
				}
			}
		} else {
			if (isList === true) {
				if (options[key] === selected_option) {
					options_string += '<option value="' + options[key] + '" selected>' + options[key] + '</option>';
				} else {
					options_string += '<option value="' + options[key] + '">' + options[key] + '</option>';
				}
			} else {
				if (key === selected_option) {
					options_string += '<option value="' + key + '" selected>' + key + '</option>';
				} else {
					options_string += '<option value="' + key + '">' + key + '</option>';
				}
			}
		}
	});
	return updateIfDifferentHTMLContents(target_object, options_string);
}

// Sleep for x ms.
function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

// Hide the global overlay.
async function hideAndResetGlobalOverlay() {
	const globalOverlay = document.getElementById("g_overlay");
	if (globalOverlay !== null) {
		globalOverlay.style.height = "0";
		globalOverlay.classList.remove("visible");
		globalOverlay.classList.add("hidden");
	}
}

function sendTimeZoneToServer() {
	// Thanks to: http://www.onlineaspect.com/2007/06/08/auto-detect-a-time-zone-with-javascript/
	// for the original.
	const currentDate = new Date();
	const janFirst = new Date(currentDate.getFullYear(), 0, 1, 0, 0, 0, 0);
	const janFirstUTCStr = janFirst.toUTCString();
	const janSecond = new Date(janFirstUTCStr.substring(0, janFirstUTCStr.lastIndexOf(" ") - 1));
	const standardTimeOffsetMinutes = (janFirst - janSecond) / (1000 * 60);

	const junFirst = new Date(currentDate.getFullYear(), 6, 1, 0, 0, 0, 0);
	const junFirstUTCStr = junFirst.toUTCString();
	const junSecond = new Date(junFirstUTCStr.substring(0, junFirstUTCStr.lastIndexOf(" ") - 1));
	const thisTimezoneOffsetMinutes = (junFirst - junSecond) / (1000 * 60);

	let isDst;
	if (standardTimeOffsetMinutes === thisTimezoneOffsetMinutes) {
		isDst = "0";
	} else {
		isDst = "1";
	}

	fetchData("/api/v3/auto/set-timezone.php", "POST",{"timezoneOffsetMinutes": thisTimezoneOffsetMinutes, "isDst": isDst});
}

// Start scripts when the page is done loading.
window.addEventListener("load", function() {
	navBarInit();
	startFileManagement();
	myAccountInit();
	statsInit();
	browserUploadInit();
	setupInstructionsInit();
	feedbackInit();

	if (typeof authInit === "function") {
		authInit();
	}

	sendTimeZoneToServer();
});
