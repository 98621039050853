// Get and populate account information for the details page.
async function fetchAccountInfo() {
	// Get and populate account information for the details page.
	if (document.visibilityState !== "hidden") {
		const accountEMailAddress = document.getElementById("info_email");
		const accountLevel = document.getElementById("info_level");
		const accountTimeCreated = document.getElementById("info_created");
		const accountUploadAPIKey = document.getElementById("info_api_key");
		const accountCopyAPIKeyButton = document.getElementById("info_api_key_copy_button");

		const accountSpaceQuota = document.getElementById("info_space_quota");
		const accountSlotQuota = document.getElementById("info_file_quota");
		const accountFileSizeLimit = document.getElementById("info_max_file_size");
		const accountDLSpeedQuota = document.getElementById("info_max_download_speed");

		const accountPatreonPlug = document.getElementById("patreon_plug");
		const accountSpaceUsed = document.getElementById("info_space_used");
		const accountSlotUsed = document.getElementById("info_quota_used");

		const response = await fetchData("/api/v3/rest/get-account-info", "GET");
		if (response.success && response.statusCode === 200) {
			const APIResponse = response.data;
			if (APIResponse.success) {
				if (accountEMailAddress.innerText === "Loading...") {
					accountCopyAPIKeyButton.addEventListener("click", function() {
						let thisCopyText = accountUploadAPIKey.getAttribute("api_key");
						navigator.clipboard.writeText(thisCopyText).then(function() {
							accountCopyAPIKeyButton.innerText = "API Key has been copied!";
							accountCopyAPIKeyButton.style.backgroundColor = "green";
							setTimeout(function() {
								accountCopyAPIKeyButton.innerText = "Copy API Key to Clipboard"
								accountCopyAPIKeyButton.style = null;
							}, 2000);
						}, function() {
							accountCopyAPIKeyButton.innerText = "Failed!";
							accountCopyAPIKeyButton.style.backgroundColor = "darkred";
						});
					});
				}

				updateIfDifferentHTMLContents(accountEMailAddress, APIResponse.account_info.email);
				updateIfDifferentHTMLContents(accountLevel, APIResponse.account_info.level);
				updateIfDifferentHTMLContents(accountTimeCreated, APIResponse.account_info.time_created);
				updateIfDifferentHTMLContents(accountUploadAPIKey, "<i>Hover to reveal!</i>");
				updateIfDifferentAttribute(accountUploadAPIKey, "api_key", APIResponse.account_info.api_key);

				updateIfDifferentHTMLContents(accountSpaceQuota, formatByteSize(APIResponse.account_limits.space_quota));
				updateIfDifferentHTMLContents(accountSlotQuota, formatNumber(APIResponse.account_limits.slots_quota));
				updateIfDifferentHTMLContents(accountFileSizeLimit, formatByteSize(APIResponse.account_limits.max_file_size));
				updateIfDifferentHTMLContents(accountDLSpeedQuota, formatNumber(APIResponse.account_limits.max_file_download_speed) + " Mbps");


				if (APIResponse.account_info.level_upgradable === true) {
					accountPatreonPlug.innerHTML = "<a href='https://www.patreon.com/LobFile' target='_blank'>Need more account space?</a>";
				}
				let spaceUsedPct = Math.round((APIResponse.account_usage.space_used / APIResponse.account_limits.space_quota) * 1000) / 10;
				let spaceUsedString = "";
				spaceUsedString += formatByteSize(APIResponse.account_usage.space_used);
				spaceUsedString += " (<b style='color: ";
				if (spaceUsedPct > 75) {
					spaceUsedString += "red";
				} else if (spaceUsedPct > 50) {
					spaceUsedString += "yellow";
				} else {
					spaceUsedString += "white";
				}
				spaceUsedString += ";'>" + spaceUsedPct + "%</b>)";
				updateIfDifferentHTMLContents(accountSpaceUsed,  spaceUsedString);

				let quotaUsedPct = Math.round((APIResponse.account_usage.slots_used / APIResponse.account_limits.slots_quota) * 1000) / 10;
				let quotaUsedString = "";
				quotaUsedString += formatNumber(APIResponse.account_usage.slots_used);
				quotaUsedString += " (<b style='color: ";
				if (quotaUsedPct > 75) {
					quotaUsedString += "red";
				} else if (quotaUsedPct > 50) {
					quotaUsedString += "yellow";
				} else {
					quotaUsedString += "white";
				}
				quotaUsedString += ";'>" + quotaUsedPct + "%</b>)"
				updateIfDifferentHTMLContents(accountSlotUsed,  quotaUsedString);

			} else if (APIResponse.error) {
				console.error("Error getting account settings: " + APIResponse.error);
			} else {
				console.error("Error getting account settings: " + response.statusCode + ".");
			}
		} else if (response.statusCode === 401) {
			window.location = "/login";
		} else {
			if (response.error) {
				console.error("Error getting account settings: " + response.statusCode + ", error: " + response.error);
			} else {
				console.error("Error getting account settings: " + response.statusCode + ".");
			}
		}
	}
}

// Get user account settings and options.
async function fetchAccountSettings() {
	// Free settings.
	const accountShowFileExtensions = document.getElementById("return_file_extension_value");
	const accountContinuousUploading = document.getElementById("continuous_uploading_value");
	const accountPreferredDomain = document.getElementById("preferred_domain_value");
	const accountInactivityAutoDelete = document.getElementById("inactivity_auto_delete_days_value");

	// Patreon settings.
	const accountFilenameLength = document.getElementById("preferred_filename_length");

	if (accountShowFileExtensions && accountContinuousUploading && accountPreferredDomain && accountInactivityAutoDelete && accountFilenameLength) {
		const response = await fetchData("/api/v3/rest/get-account-settings", "GET");
		if (response.success && response.statusCode === 200) {
			const APIResponse = response.data;
			if (APIResponse.success) {
				if (APIResponse.is_patreon_member) {
					// User is a member of Patreon, unlock the relevant settings.
					const patreonSettingsDiv = document.getElementById("patreon_settings");
					if (patreonSettingsDiv) {
						patreonSettingsDiv.classList.remove("restricted");
					}

					accountFilenameLength.disabled = false;
				}

				// All options.
				accountShowFileExtensions.checked = APIResponse.account_settings.return_file_extension_value;
				accountContinuousUploading.checked = APIResponse.account_settings.continuous_uploading_value;
				updateIfDifferentOptions(accountPreferredDomain, APIResponse.account_settings.preferred_domain_value, APIResponse.account_settings.preferred_domain_options, false);
				accountInactivityAutoDelete.value = APIResponse.account_settings.inactivity_auto_delete_days_value;

				const accountInactivityAutoDeleteStr = document.getElementById("inactivity_auto_delete_days");
				if (accountInactivityAutoDelete.value === "0") {
					accountInactivityAutoDeleteStr.innerHTML = "&nbsp;&nbsp;&nbsp;&nbsp;OFF";
				} else if (accountInactivityAutoDelete.value === "1") {
					accountInactivityAutoDeleteStr.innerHTML = "&nbsp;" + accountInactivityAutoDelete.value + " day&nbsp;";
				} else if (accountInactivityAutoDelete.value >= 10) {
					accountInactivityAutoDeleteStr.innerHTML = accountInactivityAutoDelete.value + " days";
				} else {
					accountInactivityAutoDeleteStr.innerHTML = "&nbsp;" + accountInactivityAutoDelete.value + " days";
				}
				accountInactivityAutoDelete.addEventListener("input", function() {
					if (accountInactivityAutoDeleteStr) {
						if (accountInactivityAutoDelete.value === "0") {
							accountInactivityAutoDeleteStr.innerHTML = "&nbsp;&nbsp;&nbsp;&nbsp;OFF";
						} else if (accountInactivityAutoDelete.value === "1") {
							accountInactivityAutoDeleteStr.innerHTML = "&nbsp;" + accountInactivityAutoDelete.value + " day&nbsp;";
						} else if (accountInactivityAutoDelete.value >= 10) {
							accountInactivityAutoDeleteStr.innerHTML = accountInactivityAutoDelete.value + " days";
						} else {
							accountInactivityAutoDeleteStr.innerHTML = "&nbsp;" + accountInactivityAutoDelete.value + " days";
						}
					}
				});

				// Patreon Options
				accountFilenameLength.value = APIResponse.account_settings.preferred_filename_length_value;

				const accountFilenameLengthStr = document.getElementById("filename_length_string_value");
				if (accountFilenameLength.value >= 10) {
					accountFilenameLengthStr.innerHTML = accountFilenameLength.value + " char";
				} else {
					accountFilenameLengthStr.innerHTML = "&nbsp;" + accountFilenameLength.value + " char";
				}
				accountFilenameLength.addEventListener("input", function() {
					if (accountFilenameLengthStr) {
						if (accountFilenameLength.value >= 10) {
							accountFilenameLengthStr.innerHTML = accountFilenameLength.value + " char";
						} else {
							accountFilenameLengthStr.innerHTML = "&nbsp;" + accountFilenameLength.value + " char";
						}
					}
				});
			} else if (APIResponse.error) {
				console.error("Error getting account settings: " + APIResponse.error);
			} else {
				console.error("Error getting account settings: " + response.statusCode + ".");
			}
		} else if (response.statusCode === 401) {
			window.location = "/login";
		} else {
			if (response.error) {
				console.error("Error getting account settings: " + response.statusCode + ", error: " + response.error);
			} else {
				console.error("Error getting account settings: " + response.statusCode + ".");
			}
		}
	}
}

async function updateAccountSettings() {
	// Free settings.
	const accountShowFileExtensions = document.getElementById("return_file_extension_value");
	const accountContinuousUploading = document.getElementById("continuous_uploading_value");
	const accountPreferredDomain = document.getElementById("preferred_domain_value");
	const accountInactivityAutoDelete = document.getElementById("inactivity_auto_delete_days_value");

	// Patreon settings.
	const accountFilenameLength = document.getElementById("preferred_filename_length");

	// Update the settings.
	if (accountShowFileExtensions && accountContinuousUploading && accountPreferredDomain && accountInactivityAutoDelete && accountFilenameLength) {
		let settings = {};
		settings.include_extensions = accountShowFileExtensions.checked;
		settings.continuous_uploading = accountContinuousUploading.checked;
		settings.preferred_domain = accountPreferredDomain.value;
		settings.inactivity_auto_delete_days = accountInactivityAutoDelete.value;
		settings.filename_length = accountFilenameLength.value;

		await fetchData("/api/v3/session/set-account-settings", "POST", settings);
	}
}

async function buildOverlayForChangeAPIKey() {
	const globalOverlay = document.getElementById("g_overlay");
	if (globalOverlay !== null) {
		let newHTML = "<div class='inner-global-overlay'>";
		newHTML += "<h1>Change API Key</h1>";
		newHTML += "<hr>";
		newHTML += "<b>Are you sure you want to change your API key?</b>";
		newHTML += "<br>";
		newHTML += "<i>Note:</i> This means the old one will stop working.";
		newHTML += "<br>";
		newHTML += "<br>";
		newHTML += "<button id='confirm_change_api_key' class='button-blue'>Change it</button><button id='close_overlay' class='button-yellow'>Nevermind</button>";
		newHTML += "</div>";
		globalOverlay.innerHTML = newHTML;

		const closeOverlayButton = document.getElementById("close_overlay");
		closeOverlayButton.addEventListener("click", hideAndResetGlobalOverlay);
		closeOverlayButton.addEventListener("touchstart", hideAndResetGlobalOverlay);

		const confirmChangeAPIKeyButton = document.getElementById("confirm_change_api_key");
		confirmChangeAPIKeyButton.addEventListener("click", changeAPIKey);
		confirmChangeAPIKeyButton.addEventListener("touchstart", changeAPIKey);

		globalOverlay.classList.add("visible");
		globalOverlay.style.height = "100%";
	}
}

async function buildOverlayForChangeEmail() {
	const globalOverlay = document.getElementById("g_overlay");
	if (globalOverlay !== null) {
		let newHTML = "<div class='inner-global-overlay'>";
		newHTML += "<br>";
		newHTML += "<h1>Change EMail</h1>";
		newHTML += "<hr>";
		newHTML += "<b>Enter a new E-Mail address.</b>";
		newHTML += "<br>";
		newHTML += "<i>Note:</i> you'll need to re-verify your LobFile account.";
		newHTML += "<br>";
		newHTML += "<br>";
		newHTML += "<input type='password' placeholder='Password' name='password' id='password' maxlength='64' required>";
		newHTML += "<br>";
		newHTML += "<input type='email' placeholder='New E-Mail' name='new_email' id='new_email' maxlength='64' required>";
		newHTML += "<br>";
		newHTML += "<br>";
		newHTML += "<button id='confirm_change_email' class='button-blue'>Change it</button><button id='close_overlay' class='button-yellow'>Nevermind</button>";
		newHTML += "</div>";
		globalOverlay.innerHTML = newHTML;

		const closeOverlayButton = document.getElementById("close_overlay");
		closeOverlayButton.addEventListener("click", hideAndResetGlobalOverlay);
		closeOverlayButton.addEventListener("touchstart", hideAndResetGlobalOverlay);

		const confirmChangeEMailButton = document.getElementById("confirm_change_email");
		confirmChangeEMailButton.addEventListener("click", changeEMail);
		confirmChangeEMailButton.addEventListener("touchstart", changeEMail);

		globalOverlay.classList.add("visible");
		globalOverlay.style.height = "100%";
	}
}

// Create the overlay for account deletions.
async function buildOverlayForDeleteAccount() {
	const globalOverlay = document.getElementById("g_overlay");
	if (globalOverlay !== null) {
		let newHTML = "<div class='inner-global-overlay'>";
		newHTML += "<h1>Delete Account</h1>";
		newHTML += "<hr>";
		newHTML += "<b>Permanently delete your account and data?</b>";
		newHTML += "<br>";
		newHTML += "<i>Note:</i> This cannot be un-done.";
		newHTML += "<br>";
		newHTML += "<br>";
		newHTML += "<button id='confirm_delete_account' class='button-red'>Delete It</button><button id='close_overlay' class='button-yellow'>Nevermind</button>";
		newHTML += "</div>";
		globalOverlay.innerHTML = newHTML;

		const closeOverlayButton = document.getElementById("close_overlay");
		closeOverlayButton.addEventListener("click", hideAndResetGlobalOverlay);
		closeOverlayButton.addEventListener("touchstart", hideAndResetGlobalOverlay);

		const confirmChangeAPIKeyButton = document.getElementById("confirm_delete_account");
		confirmChangeAPIKeyButton.addEventListener("click", deleteAccount);
		confirmChangeAPIKeyButton.addEventListener("touchstart", deleteAccount);

		globalOverlay.classList.add("visible");
		globalOverlay.style.height = "100%";
	}
}

// Change account API key.
async function changeAPIKey() {
	const globalOverlay = document.getElementById("g_overlay");
	if (globalOverlay !== null) {
		let newHTML = "<div class='inner-global-overlay'>";
		newHTML += "<h1>Change API Key</h1>";
		newHTML += "<hr>";
		newHTML += "<b>Changing API key..</b>";
		newHTML += "<br>";
		newHTML += "<br>";
		newHTML += "<br>";
		newHTML += "<button id='close_overlay' class='button-blue'>Okay</button>";
		newHTML += "</div>";
		globalOverlay.innerHTML = newHTML;

		const closeOverlayButton = document.getElementById("close_overlay");
		closeOverlayButton.addEventListener("click", hideAndResetGlobalOverlay);
		closeOverlayButton.addEventListener("touchstart", hideAndResetGlobalOverlay);

		const response = await fetchData("/api/v3/session/change-api-key", "POST");
		if (response.success) {
			let newHTML = "<div class='inner-global-overlay'>";
			newHTML += "<h1>Change API Key</h1>";
			newHTML += "<hr>";
			newHTML += "<b>API key changed!</b>";
			newHTML += "<br>";
			newHTML += "<br>";
			newHTML += "<br>";
			newHTML += "<button id='close_overlay' class='button-blue'>Okay</button>";
			newHTML += "</div>";
			globalOverlay.innerHTML = newHTML;

			const closeOverlayButton = document.getElementById("close_overlay");
			closeOverlayButton.addEventListener("click", hideAndResetGlobalOverlay);
			closeOverlayButton.addEventListener("touchstart", hideAndResetGlobalOverlay);

			await fetchAccountInfo();
		}
	}
}

// Delete account.
async function deleteAccount() {
	const globalOverlay = document.getElementById("g_overlay");
	if (globalOverlay !== null) {
		let newHTML = "<div class='inner-global-overlay'>";
		newHTML += "<h1>Delete Account</h1>";
		newHTML += "<hr>";
		newHTML += "<b>Deleting account...</b>";
		newHTML += "<br>";
		newHTML += "<i>Note:</i> This could take awhile..";
		newHTML += "<br>";
		newHTML += "<br>";
		newHTML += "<button id='close_overlay' class='button-blue'>Okay</button>";
		newHTML += "</div>";
		globalOverlay.innerHTML = newHTML;

		const closeOverlayButton = document.getElementById("close_overlay");
		closeOverlayButton.addEventListener("click", hideAndResetGlobalOverlay);
		closeOverlayButton.addEventListener("touchstart", hideAndResetGlobalOverlay);

		const response = await fetchData("/api/v3/session/delete-account", "DELETE");
		if (response.success) {
			let newHTML = "<div class='inner-global-overlay'>";
			newHTML += "<h1>Delete Account</h1>";
			newHTML += "<hr>";
			newHTML += "<b>Account deleted, redirecting...</b>";
			newHTML += "<br>";
			newHTML += "<br>";
			newHTML += "<br>";
			newHTML += "<button id='close_overlay' class='button-blue'>Goodbye</button>";
			newHTML += "</div>";
			globalOverlay.innerHTML = newHTML;

			const closeOverlayButton = document.getElementById("close_overlay");
			closeOverlayButton.addEventListener("click", hideAndResetGlobalOverlay);
			closeOverlayButton.addEventListener("touchstart", hideAndResetGlobalOverlay);

			window.location.replace("/");
		}
	}
}

// Change EMail.
async function changeEMail() {
	const globalOverlay = document.getElementById("g_overlay");
	let emailInputBox = document.getElementById("new_email");
	let passwordInputBox = document.getElementById("password");
	if (globalOverlay !== null) {
		if (emailInputBox.value === "" || emailInputBox.value === null || passwordInputBox.value === null || passwordInputBox.value === "") {
			let newHTML = "<div class='inner-global-overlay'>";
			newHTML += "<br>";
			newHTML += "<h1>Change EMail</h1>";
			newHTML += "<hr>";
			newHTML += "<b>Please input a valid E-Mail address.</b>";
			newHTML += "<br>";
			newHTML += "<i>Note:</i> you'll need to re-verify your LobFile account.";
			newHTML += "<br>";
			newHTML += "<br>";
			newHTML += "<input type='password' placeholder='Password' name='password' id='password' maxlength='64' value='" + (passwordInputBox.value || "") + "' required>";
			newHTML += "<br>";
			newHTML += "<input type='email' placeholder='New E-Mail' name='new_email' id='new_email' maxlength='64' value='" + (emailInputBox.value || "") + "' required>";
			newHTML += "<br> (Missing E-Mail or password.)";
			newHTML += "<br>";
			newHTML += "<button id='confirm_change_email' class='button-blue'>Change it</button><button id='close_overlay' class='button-blue'>Nevermind</button>";
			newHTML += "</div>";
			globalOverlay.innerHTML = newHTML;

			const closeOverlayButton = document.getElementById("close_overlay");
			closeOverlayButton.addEventListener("click", hideAndResetGlobalOverlay);
			closeOverlayButton.addEventListener("touchstart", hideAndResetGlobalOverlay);

			const confirmChangeEMailButton = document.getElementById("confirm_change_email");
			confirmChangeEMailButton.addEventListener("click", changeEMail);
			confirmChangeEMailButton.addEventListener("touchstart", changeEMail);
		} else {
			let newHTML = "<div class='inner-global-overlay'>";
			newHTML += "<br>";
			newHTML += "<h1>Change EMail</h1>";
			newHTML += "<hr>";
			newHTML += "<b>Processing...</b>";
			newHTML += "<br>";
			newHTML += "<i>Note:</i> you'll need to re-verify your LobFile account.";
			newHTML += "<br>";
			newHTML += "<br>";
			newHTML += "<br>";
			newHTML += "<br>";
			newHTML += "<br>";
			newHTML += "<button id='close_overlay' class='button-blue'>Okay</button>";
			newHTML += "</div>";
			globalOverlay.innerHTML = newHTML;

			const closeOverlayButton = document.getElementById("close_overlay");
			closeOverlayButton.addEventListener("click", hideAndResetGlobalOverlay);
			closeOverlayButton.addEventListener("touchstart", hideAndResetGlobalOverlay);


			await fetchData("/api/v3/session/change-email", "POST", {"password": passwordInputBox.value, "new_email": emailInputBox.value}, changeEMailCallback);
		}
	}
}

async function changeEMailCallback(data) {
	const globalOverlay = document.getElementById("g_overlay");
	if (globalOverlay !== null) {
		if (data.success === true) {
			let newHTML = "<div class='inner-global-overlay'>";
			newHTML += "<br>";
			newHTML += "<h1>Change EMail</h1>";
			newHTML += "<hr>";
			newHTML += "<b>Success!</b>";
			newHTML += "<br>";
			newHTML += "<i>Note:</i> you'll need to re-verify your LobFile account.";
			newHTML += "<br>Check your new EMail <b>( " + data.email + " )</b> for the verification link.";
			newHTML += "<br>";
			newHTML += "<br>";
			newHTML += "<br>";
			newHTML += "<br>";
			newHTML += "<button id='close_overlay' class='button-blue'>Okay</button>";
			newHTML += "</div>";
			globalOverlay.innerHTML = newHTML;

			const closeOverlayButton = document.getElementById("close_overlay");
			closeOverlayButton.addEventListener("click", hideAndResetGlobalOverlay);
			closeOverlayButton.addEventListener("touchstart", hideAndResetGlobalOverlay);

			await fetchAccountInfo();
		} else {
			let newHTML = "<div class='inner-global-overlay'>";
			newHTML += "<br>";
			newHTML += "<h1>Change EMail</h1>";
			newHTML += "<hr>";
			newHTML += "<b>Failed to change EMail!</b>";
			newHTML += "<br>";
			newHTML += "<br>";
			newHTML += "<br><b>Error:</b> " + data.error;
			newHTML += "<br>";
			newHTML += "<br>";
			newHTML += "<br>Try again or contact support.";
			newHTML += "<br>";
			newHTML += "<button id='close_overlay' class='button-blue'>Okay</button>";
			newHTML += "</div>";
			globalOverlay.innerHTML = newHTML;

			const closeOverlayButton = document.getElementById("close_overlay");
			closeOverlayButton.addEventListener("click", hideAndResetGlobalOverlay);
			closeOverlayButton.addEventListener("touchstart", hideAndResetGlobalOverlay);
		}
	}
}

let CurrentlyHoveringAPIKey = false;
// Show API key on hover.
function showAPIKey() {
	CurrentlyHoveringAPIKey = true;
	const showAPIKeyOnHover = document.getElementById("info_api_key");
	if (showAPIKeyOnHover) {
		updateIfDifferentHTMLContents(showAPIKeyOnHover, showAPIKeyOnHover.getAttribute("api_key"));
	}
}

// Hide API key on hover.
function hideAPIKey() {
	CurrentlyHoveringAPIKey = false;
	const showAPIKeyOnHover = document.getElementById("info_api_key");
	if (showAPIKeyOnHover) {
		showAPIKeyOnHover.innerHTML = "<i>Hover to reveal!</i>";
	}
}

// Initialize.
function myAccountInit() {
	// Show API key on hover.
	const showAPIKeyOnHover = document.getElementById("info_api_key");
	if (showAPIKeyOnHover) {
		showAPIKeyOnHover.addEventListener("touch", showAPIKey);
		showAPIKeyOnHover.addEventListener("mouseover", showAPIKey);
		showAPIKeyOnHover.addEventListener("mouseleave", hideAPIKey);
	}

	// Get and populate account settings.
	const accountShowFileExtensions = document.getElementById("return_file_extension_value");
	const accountContinuousUploading = document.getElementById("continuous_uploading_value");
	const accountPreferredDomain = document.getElementById("preferred_domain_value");
	const accountInactivityAutoDelete = document.getElementById("inactivity_auto_delete_days_value");
	const accountFilenameLength = document.getElementById("preferred_filename_length");
	if (accountShowFileExtensions && accountContinuousUploading && accountPreferredDomain && accountInactivityAutoDelete && accountFilenameLength) {
		fetchAccountSettings();

		// If the tab is re-opened/focused after it being open, make sure the data is good.
		window.addEventListener("focus", fetchAccountSettings);

		accountPreferredDomain.addEventListener("change", updateAccountSettings);
		accountShowFileExtensions.addEventListener("change", updateAccountSettings);
		accountContinuousUploading.addEventListener("change", updateAccountSettings);
		accountInactivityAutoDelete.addEventListener("change", updateAccountSettings);
		accountFilenameLength.addEventListener("change", updateAccountSettings);
	}

	// Delete account button.
	const deleteAccountButton = document.getElementById("delete_account_button");
	if (deleteAccountButton !== null) {
		deleteAccountButton.addEventListener("touch", buildOverlayForDeleteAccount);
		deleteAccountButton.addEventListener("click", buildOverlayForDeleteAccount);
	}

	// Change API key button.
	const changeAPIKeyButton = document.getElementById("change_api_key_button");
	if (changeAPIKeyButton !== null) {
		changeAPIKeyButton.addEventListener("touch", buildOverlayForChangeAPIKey);
		changeAPIKeyButton.addEventListener("click", buildOverlayForChangeAPIKey);
	}

	// Change EMail button.
	const changeEMailButton = document.getElementById("change_email_button");
	if (changeEMailButton !== null) {
		changeEMailButton.addEventListener("touch", buildOverlayForChangeEmail);
		changeEMailButton.addEventListener("click", buildOverlayForChangeEmail);
	}

	if (document.URL.indexOf("/my-account") >= 0) {
		// Fetch account info loop.
		fetchAccountInfo();

		// If the tab is re-opened/focused after it being open, make sure the data is good.
		window.addEventListener("focus", fetchAccountInfo);
	}
}
